import StimulusTomSelect from "stimulus-tom-select";

export default class extends StimulusTomSelect {
  static values = {
    value: { type: String, default: 'id' },
    label: { type: String, default: 'display' },
    path: String,
  };
  connect() {
    if (!this.hasPathValue) {
      throw new Error("Path value must be specified.");
    }
    super.connect();
  }

  initTomSelect() {
    this.options = {
      valueField: this.valueValue,
      labelField: this.labelValue,
      searchField: this.labelValue,
      maxItems: 1,
      load: (query, callback) => {
        if (!query.length) return callback()

        const url = `${this.pathValue}${encodeURIComponent(query)}`
        const req = new XMLHttpRequest()
        const csrfNode = document.head.querySelector('meta[name="csrf-token"]')

        req.open('GET', url, true);
        req.setRequestHeader('Accept', 'application/json');

        if (typeof csrfNode.content === "string")
          req.setRequestHeader("X-CSRF-Token", csrfNode.content)

        req.addEventListener('load', () => {
          if (req.status >= 200 && req.status < 400) {
            const data = JSON.parse(req.responseText);

            this.select.clear();
            this.select.clearOptions();

            callback(data);
          } else {
            console.log('Network response was not ok.')
          }
        });
        req.send();
      },
      render: {
        option_create: function (data, escape) {
          return '<div class="create">Create new record for: <strong>' + escape(data.input) + '</strong>&hellip;</div>';
        },
      },
      ...this.optionsValue,
    };

    let focusAfterInit = false;
    if (document.activeElement == this.element) {
      focusAfterInit = true
    }

    super.initTomSelect()

    if (focusAfterInit) {
      this.select.focus();
    }
  }
}
