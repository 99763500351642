import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--collapsible"
export default class extends Controller {
  static targets = ["toggleState"];

  toggle() {
    const rootState = this.element.getAttribute("data-state");
    this.element.setAttribute(
      "data-state",
      rootState === "closed" ? "open" : "closed"
    );

    this.toggleStateTargets.forEach((element) => {
      const currentState = element.getAttribute("data-state");
      const newState = currentState === "closed" ? "open" : "closed";
      element.setAttribute("data-state", newState);
    });
  }
}
