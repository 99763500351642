import { Controller } from '@hotwired/stimulus';
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  static targets = ["field"];
  static values = {
    delimiters: { type: String, default: ",| " }
  };

  tagifyInstance = null;

  connect() {
    this.initializeTagify();
  }

  initializeTagify() {
    this.tagifyInstance = new Tagify(this.fieldTarget, { delimiters: this.delimitersValue });
  }
}
