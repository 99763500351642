/**
 * tom_select_assign_controller.js
 *
 * This Stimulus controller is designed to dynamically update one or more fields based on the selection
 * in a Tom Select dropdown menu. When a selection is made, the controller extracts specified data
 * attributes from the selected item and assigns them to corresponding fields.
 *
 * How to use:
 * 1. Include this controller in your Stimulus setup.
 * 2. Add `data-controller="tom_select_assign"` to your Tom Select dropdown element.
 * 3. Define the mappings between the data attributes and the fields using the `data-tom_select_assign-mappings-value` attribute.
 *
 * The format for `data-tom_select_assign-mappings-value` is:
 * "dataAttribute1:#selector1,dataAttribute2:#selector2"
 *
 * For example:
 * "email:#email_address,phone1:#phone_number"
 *
 * This will map the `email` data attribute to the field with ID `email_address` and the `phone1` data attribute to the field with ID `phone_number`.
 *
 * HTML Example:
 * <select data-controller="tom_select_assign"
 *         data-tom_select_assign-mappings-value="email:#email_address,phone1:#phone_number">
 * </select>
 * <input type="text" id="email_address">
 * <input type="text" id="phone_number">
 *
 * Multiple Controllers:
 * If you need to assign multiple Stimulus controllers to the same element, separate them with a space in the `data-controller` attribute.
 * Example:
 * <select data-controller="remote-tom-select tom_select_assign"
 *         data-tom_select_assign-mappings-value="email:#email_address,phone1:#phone_number"
 *         data-another_controller-some-value="someValue">
 * </select>
 *
 * Dependencies:
 * - Tom Select (https://tom-select.js.org/)
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { mappings: String };

  connect() {
    this.mappingPairs = this.parseMappings(this.mappingsValue);
    this.element.addEventListener('change', this.handleSelection);
  }

  disconnect() {
    this.element.removeEventListener('change', this.handleSelection);
  }

  parseMappings(mappings) {
    return mappings.split(',').map(pair => {
      const [key, selector] = pair.split(':');
      return { key: key.trim(), selector: selector.trim() };
    });
  }

  handleSelection = (event) => {
    if (!this.element.tomselect) {
      throw new Error('Tom Select is not initialized on this element');
    }

    const selectedValue = this.element.tomselect.getValue();
    const selectedData = selectedValue ? this.element.tomselect.options[selectedValue] : {};

    this.mappingPairs.forEach(mapping => {
      const field = document.querySelector(mapping.selector);
      if (field) {
        field.value = selectedData[mapping.key] || '';
      }
    });
  }
}
