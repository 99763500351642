import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["menu", "overlay", "container"]

  show() {
    enter(this.containerTarget)
    enter(this.menuTarget)
    enter(this.overlayTarget)
  }

  hide() {
    leave(this.menuTarget)
    leave(this.overlayTarget).then(() => leave(this.containerTarget))
  }
}
