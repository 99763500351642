import {
  Controller
} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["button", "status"]

  connect() {
    if (this.hasStatusTarget) {
      this.statusTarget.innerHTML = "Stimulus Controller Connected";
    }
    console.log("Test Stimulus Controller Connected");
  }

  click() {
    var now = new Date();
    var time = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    if (this.hasStatusTarget) {
      this.statusTarget.innerHTML = "Clicked at " + time;
    }
    console.log("Test Stimulus Controller Clicked at " + time)
  }

  changed() {
    var now = new Date();
    var time = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    if (this.hasStatusTarget) {
      this.statusTarget.innerHTML = "Changed at " + time;
    }
    console.log("Test Stimulus Controller Changed at " + time)
  }
}
