import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "button", "slider", "field" ];
  static values = { reloadOnClick: Boolean };

  click(event) {
    let buttonEnabledClass = this.buttonTarget.getAttribute('data-enabled-class');
    let buttonDisabledClass = this.buttonTarget.getAttribute('data-disabled-class');
    let sliderEnabledClass = this.sliderTarget.getAttribute('data-enabled-class');
    let sliderDisabledClass = this.sliderTarget.getAttribute('data-disabled-class');

    if(this.buttonTarget.classList.contains(buttonEnabledClass)) {
      this.buttonTarget.classList.remove(buttonEnabledClass);
      this.sliderTarget.classList.remove(sliderEnabledClass);
      this.buttonTarget.classList.add(buttonDisabledClass);
      this.sliderTarget.classList.add(sliderDisabledClass);
      this.fieldTarget.value = 0;
    } else {
      this.buttonTarget.classList.remove(buttonDisabledClass);
      this.sliderTarget.classList.remove(sliderDisabledClass);
      this.buttonTarget.classList.add(buttonEnabledClass);
      this.sliderTarget.classList.add(sliderEnabledClass);
      this.fieldTarget.value = 1;
    }

    if (this.reloadOnClickValue) {
      let paramName = this.fieldTarget.getAttribute("name");
      let queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramName, this.fieldTarget.value);
      window.location.search = queryParams.toString();
    }
  }
}