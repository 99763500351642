import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["deleteButton"];

  connect() {
    this.showHideDeleteButtons();
  }

  add(event) {
    event.preventDefault()
    let table = this.element.querySelector('table');
    let tBody = table.querySelector('tbody');

    let newRow = tBody.querySelector('tr:last-child').cloneNode(true);
    this.clearInputs(newRow)
    tBody.append(newRow)

    let startDateFields = document.querySelectorAll('input[name="room[schedule][start_date][]"]');
    let startDateField = startDateFields[startDateFields.length - 1];

    this.showDateField(startDateField)
    this.showHideDeleteButtons()
  }

  del(event) {
    event.preventDefault();
    if (this.rowCount() > 1) {
      let tr = event.currentTarget.closest("tr");
      tr.remove()
      this.showHideDeleteButtons()
    }
  }

  clearInputs(el) {
    el.querySelectorAll("input, select").forEach(function (input) {
      input.value = "";
    });
  }

  rowCount() {
    let table = this.element.querySelector('table');
    let tBody = table.querySelector('tbody');
    return tBody.querySelectorAll("tr").length;
  }

  showHideDeleteButtons() {
    let shouldHide = this.rowCount() <= 1
    if (shouldHide) {
      this.deleteButtonTargets.forEach(button => button.classList.add("invisible"));
    } else {
      this.deleteButtonTargets.forEach(button => button.classList.remove("invisible"));
    }
  }

  showDateField(el) {
    el.classList.remove("hidden");
    el.nextElementSibling.classList.add('infinity');
    el.nextElementSibling.style.display = 'none';
  }
}
