import {
  Controller
} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["program", "room"]

  connect() {
    this.programSelected();
  }

  programSelected() {
    this.populateRooms(this.programTarget.value, this.roomTarget.value);
  }

  populateRooms(sourceId, targetId = null) {
    fetch(`/programs/${sourceId}/rooms`, {
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then(data => {
        const selectBox = this.roomTarget;
        selectBox.innerHTML = '';

        const blank = document.createElement('option');
        blank.value = "";
        blank.innerHTML = "";
        selectBox.appendChild(blank);

        data.forEach(item => {
          const opt = document.createElement('option');
          opt.value = item.id;
          opt.innerHTML = item[this.data.get('displayAttribute')];
          opt.selected = targetId === item.id;
          selectBox.appendChild(opt);
        });
      });
  }
}