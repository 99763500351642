import { Controller } from "@hotwired/stimulus"
import Cropper from "cropperjs"

export default class extends Controller {
  static targets = ["image"]
  static values = { model: String }

  static values = {
    model: String,
    viewMode: { type: Number, default: 1 },
    dragMode: { type: String, default: 'crop' },
    circle: { type: Boolean, default: false },
    aspectRatio: { type: String, default: "" }
  }

  connect() {
    if (this.circleValue) {
      let style = document.createElement('style')
      style.innerHTML = '.cropper-view-box, .cropper-face { border-radius: 50% !important; }'
      style.innerHTML += '.cropper-view-box { outline: 0; box-shadow: 0 0 0 1px #39f; }'
      document.head.appendChild(style)
    }

    let style = document.createElement('style')
    style.innerHTML = '.cropper-bg { background-image: none !important; } .cropper-modal { background-color: rgba(0,0,0,0) !important; }'
    document.head.appendChild(style)

    this.data.set("has-image", this.imageTarget.src != "")
  }

  changed() {
    let _this = this
    const cropper = new Cropper(this.imageTarget, {
      crop(event) {
        _this.crop_x().value = event.detail.x
        _this.crop_y().value = event.detail.y
        _this.crop_width().value = event.detail.width
        _this.crop_height().value = event.detail.height
      },
      aspectRatio: _this.aspectRatioValue,
      viewMode: _this.viewModeValue,
      dragMode: _this.dragModeValue,
      responsive: true
    })

    this.data.set("has-image", this.imageTarget.src != "")

    // Set initial values
    this.crop_x().value = cropper.x
    this.crop_y().value = cropper.y
    this.crop_width().value = cropper.width
    this.crop_height().value = cropper.height
  }

  crop_x() {
    if (this._crop_x == undefined) {
      this._crop_x = document.createElement("input")
      this._crop_x.name = `${this.modelValue}[crop_x]`
      this._crop_x.type = "hidden"
      this.imageTarget.parentNode.insertBefore(this._crop_x, this.imageTarget.nextSibling)
    }
    return this._crop_x
  }

  crop_y() {
    if (this._crop_y == undefined) {
      this._crop_y = document.createElement("input")
      this._crop_y.name = `${this.modelValue}[crop_y]`
      this._crop_y.type = "hidden"
      this.imageTarget.parentNode.insertBefore(this._crop_y, this.imageTarget.nextSibling)
    }
    return this._crop_y
  }

  crop_width() {
    if (this._crop_width == undefined) {
      this._crop_width = document.createElement("input")
      this._crop_width.name = `${this.modelValue}[crop_width]`
      this._crop_width.type = "hidden"
      this.imageTarget.parentNode.insertBefore(this._crop_width, this.imageTarget.nextSibling)
    }
    return this._crop_width
  }

  crop_height() {
    if (this._crop_height == undefined) {
      this._crop_height = document.createElement("input")
      this._crop_height.name = `${this.modelValue}[crop_height]`
      this._crop_height.type = "hidden"
      this.imageTarget.parentNode.insertBefore(this._crop_height, this.imageTarget.nextSibling)
    }
    return this._crop_height
  }

}
