import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["params"];

  async exportToExcel() {
    let url = new URL(document.location.href);
    let params = url.searchParams;
    params.set("intent", "excel");
    url.search = params.toString();

    const link = document.createElement("a");
    link.href = url.toString();
    link.download = "students.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
