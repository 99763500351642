import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--message"
export default class extends Controller {
  static values = {
    autoDismiss: { type: Boolean, default: false },
    autoDismissDelay: { type: Number, default: 5 },
  };

  connect() {
    if (this.element.classList.contains("hidden")) {
      this.element.dataset.state = "open";
      this.element.classList.remove("hidden");
    }

    if (this.autoDismissValue) {
      this.timeout = setTimeout(() => {
        this.dismiss();
      }, this.autoDismissDelayValue * 1000);
    }
  }

  dismiss() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.dismissed = true;
    this.element.dataset.state = "closed";

    this.element.addEventListener(
      "animationend",
      () => {
        this.element.remove();
      },
      { once: true }
    );
  }
}
