// Auto-hide navbar on scroll

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navbar"];

  initialize() {
    this.lastScrollPosition = 0;
    this.isNavbarHidden = false;
    this.handleScroll = this.debounce(this.handleScroll.bind(this), 10);
    console.log("NavbarController#initialize")
  }

  connect() {
    window.addEventListener('scroll', this.handleScroll);
  }

  disconnect() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    if (currentScrollPosition > this.lastScrollPosition && currentScrollPosition > 100) {
      if (!this.isNavbarHidden) {
        this.hideNavbar();
      }
    } else if (this.lastScrollPosition - currentScrollPosition > 20) {
      if (this.isNavbarHidden) {
        this.showNavbar();
      }
    } else if (currentScrollPosition < 100) {
      this.showNavbar();
    }
    this.lastScrollPosition = currentScrollPosition;
  }

  hideNavbar() {
    this.isNavbarHidden = true;
    this.updateNavbarClasses(true);
  }

  showNavbar() {
    this.isNavbarHidden = false;
    this.updateNavbarClasses(false);
  }

  updateNavbarClasses(hide) {
    const showClasses = this.navbarTarget.dataset.showClasses.split(' ');
    const hideClasses = this.navbarTarget.dataset.hideClasses.split(' ');

    if (hide) {
      this.navbarTarget.classList.remove(...showClasses);
      this.navbarTarget.classList.add(...hideClasses);
    } else {
      this.navbarTarget.classList.remove(...hideClasses);
      this.navbarTarget.classList.add(...showClasses);
    }
  }

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func.apply(this, args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
}
