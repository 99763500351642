import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldContainer", "activationContainer"]

  showField() {
    this.fieldContainerTarget.classList.remove("hidden")
    this.activationContainerTarget.classList.add("hidden")

    setTimeout(() => {
      const field = this.fieldContainerTarget.querySelector("input[type=text]")
      field.focus()
    }, 20)
  }
}
