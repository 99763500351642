import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  reset() {
    this.element.reset();
    this.buttonTarget.disabled = false;
    let focusEl = this.element.querySelector("input:not([type=hidden]), select, textarea, [tabindex]:not([tabindex='-1'])");
    focusEl.focus()
  }
}
