import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["start", "end"];

  connect() {
    if (!this.startTarget) {
      console.error("Start target not found");
      return;
    }

    this.startTarget.addEventListener("duetChange", this.valueChanged.bind(this));

    if (this.endTarget) {
      this.endTarget.addEventListener("duetChange", this.valueChanged.bind(this));
    }

    this.rememberDistance();
  }

  valueChanged(event) {
    if (event.target == this.startTarget) {
      this.setEndMinDate();
    }
    this.rememberDistance();
  }

  rememberDistance() {
    if (!this.endDate()) return null;
    let difference = this.endDate() - this.startDate();
    this.distance = Math.ceil(difference / (1000 * 3600 * 24));
  }

  setEndMinDate() {
    if (!this.startDate()) return false;
    if (this.startDate() > this.endDate()) {
      let newDate = this.addDistanceToStartDate();
      this.endTarget.setAttribute("value", this.stringifyDate(newDate));
    }

    this.endTarget.min = this.stringifyDate(this.startTarget.getAttribute("value"));
  }

  startDate() {
    if (this.endTarget.getAttribute("value").trim().length == 0) return null;
    return Date.parse(this.startTarget.getAttribute("value"));
  }

  endDate() {
    if (!this.endTarget || this.endTarget.getAttribute("value").trim().length == 0) return null;
    return Date.parse(this.endTarget.getAttribute("value"));
  }

  stringifyDate(date) {
    return new Date(date).toISOString().substring(0, 10);
  }

  addDistanceToStartDate() {
    if (this.distance && this.distance > 0) {
      let newDate = new Date(this.startDate());
      newDate = new Date(newDate.setDate(newDate.getDate() + (this.distance || 60)));
      return newDate;
    } else {
      return this.startDate();
    }
  }

  disconnect() {
    this.startTarget.removeEventListener("change", this.valueChanged.bind(this));
    this.endTarget.removeEventListener("change", this.valueChanged.bind(this));
  }
}
