// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"
import { registerControllers } from "stimulus-vite-helpers";

// Register each controller with Stimulus
const controllers = import.meta.glob("./**/*_controller.js", { eager: true });
registerControllers(application, controllers);

import { Dropdown, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
// application.register("alert", Alert);
// application.register("autosave", Autosave);
// application.register("dropdown", Dropdown);
// application.register("modal", Modal);
application.register("tabs", Tabs);
application.register("popover", Popover);
application.register("toggle", Toggle);
application.register("slideover", Slideover);

import Timeago from "stimulus-timeago"
application.register("timeago", Timeago)

import Sortable from '@stimulus-components/sortable'
application.register('sortable', Sortable)

// Manually register Flatpickr as a stimulus controller
import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

// CableReady
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'
CableReady.initialize({ consumer })

import setupUltimateTurboModal from "ultimate_turbo_modal";
setupUltimateTurboModal(application);

import StimulusTomSelect from "stimulus-tom-select";
application.register("tom-select", StimulusTomSelect);

import TomSelectAssign from "./tom_select_assign_controller";
application.register("tom-select-assign", TomSelectAssign);


// Controllers from components - view_component-contrib
const viewComponentControllers = import.meta.glob("./../../components/**/controller.js", { eager: true });

for (let path in viewComponentControllers) {
  let module = viewComponentControllers[path];
  let name = path
    .match(/\/components\/(.+)\/controller\.js$/)[1]
    .replaceAll("/", "-")
    .replaceAll("_", "-");
  application.register(name, module.default);
}
