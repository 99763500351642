import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--proxy"
export default class extends Controller {
  static values = {
    controllerName: String,
    method: String,
    selector: String,
  };

  trigger() {
    const elements = Array.from(document.querySelectorAll(this.selectorValue));

    elements.forEach((element) => {
      const controllerInstance =
        this.application.getControllerForElementAndIdentifier(
          element,
          this.controllerNameValue
        );
      if (
        controllerInstance &&
        typeof controllerInstance[this.methodValue] === "function"
      ) {
        controllerInstance[this.methodValue]();
      }
    });
  }
}
