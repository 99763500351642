import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--modal"
export default class extends Controller {
  static targets = ["content", "backdrop", "dialog"];
  connect() {}

  open() {
    this.backdropTarget.dataset.state = "open";
    this.dialogTarget.dataset.state = "open";
    this.contentTarget.classList.remove("hidden");
    this.contentTarget.classList.add("flex");
  }

  close() {
    const animationEndHandler = () => {
      this.contentTarget.classList.remove("flex");
      this.contentTarget.classList.add("hidden");
    };

    this.contentTarget.addEventListener("animationend", animationEndHandler, {
      once: true,
    });

    this.backdropTarget.dataset.state = "closed";
    this.dialogTarget.dataset.state = "closed";
  }
}
