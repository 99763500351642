import { Application, defaultSchema } from "@hotwired/stimulus"
import { Autocomplete } from "stimulus-autocomplete"
import Chart from '@stimulus-components/chartjs'

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, slash: "/" },
}

const application = Application.start(document.documentElement, customSchema)

application.register("autocomplete", Autocomplete)
application.register('chart', Chart)

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV !== 'production'
window.Stimulus = application

export { application }
