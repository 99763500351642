import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--tab-panel"
export default class extends Controller {
  static targets = ["tab", "content"];

  connect() {
    const selectedTab = this.tabTargets.find(
      (tab) => tab.dataset.state === "selected"
    );
    this.selectTab(selectedTab || this.tabTargets[0]);
  }

  selectTab(tab) {
    this.tabTargets.forEach((t) => {
      t.dataset.state = t === tab ? "selected" : "";
    });

    let contentId = tab.dataset.tabId;
    this.contentTargets.forEach((c) => {
      if (c.dataset.contentId == contentId) {
        c.classList.remove("hidden");
      } else {
        c.classList.add("hidden");
      }
    });
  }

  changeTab(event) {
    let tab = event.currentTarget;
    this.selectTab(tab);
  }
}
