import { Controller } from "@hotwired/stimulus"
import { enter, leave, toggle } from "el-transition"
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["trigger", "dropdown"]

  connect() {
    useClickOutside(this)
    this.triggerTarget.setAttribute("aria-haspopup", "true")
    this.triggerTarget.setAttribute("aria-expanded", "false")

    if (!this.dropdownTarget.hasAttribute("data-transition-enter")) {
      this.dropdownTarget.setAttribute("data-transition-enter", "transition ease-out duration-100 transform")
    }
    if (!this.dropdownTarget.hasAttribute("data-transition-enter-start")) {
      this.dropdownTarget.setAttribute("data-transition-enter-start", "transform opacity-0 scale-95")
    }
    if (!this.dropdownTarget.hasAttribute("data-transition-enter-end")) {
      this.dropdownTarget.setAttribute("data-transition-enter-end", "transform opacity-100 scale-100")
    }
    if (!this.dropdownTarget.hasAttribute("data-transition-leave")) {
      this.dropdownTarget.setAttribute("data-transition-leave", "transition ease-in duration-75")
    }
    if (!this.dropdownTarget.hasAttribute("data-transition-leave-start")) {
      this.dropdownTarget.setAttribute("data-transition-leave-start", "transform opacity-100 scale-100")
    }
    if (!this.dropdownTarget.hasAttribute("data-transition-leave-end")) {
      this.dropdownTarget.setAttribute("data-transition-leave-end", "transform opacity-0 scale-95")
    }

    this.triggerTarget.addEventListener("click", this.toggle.bind(this))
    this.dropdownTarget.querySelectorAll("a").forEach((element) => {
      if (element == this.triggerTarget) return
      element.addEventListener("click", this.hide.bind(this))
    })
  }

  disconnect() {
    this.triggerTarget.removeEventListener("click", this.toggle.bind(this))
    this.dropdownTarget.querySelectorAll("a").forEach((element) => {
      if (element == this.triggerTarget) return
      element.removeEventListener("click", this.hide.bind(this))
    })
  }

  clickOutside(event) {
    this.hide()
  }

  toggle() {
    toggle(this.dropdownTarget)
    this.triggerTarget.setAttribute("aria-expanded", this.dropdownTarget.classList.contains("hidden") ? "false" : "true")
  }

  show() {
    enter(this.dropdownTarget)
    this.triggerTarget.setAttribute("aria-expanded", "true")
  }

  hide() {
    leave(this.dropdownTarget)
    this.triggerTarget.setAttribute("aria-expanded", "false")
  }
}
