import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", this.preventDefaultIfInvalid.bind(this));
  }
  preventDefaultIfInvalid(event) {
    let isValid = this.validateForm(this.element);
    if (!isValid) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  validateForm() {
    let isValid = true;

    let requiredFieldSelectors = "textarea:required, input:required";
    let requiredFields = this.element.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        field.focus();

        isValid = false;

        return false;
      }
    });

    // If we already know we're invalid, just return false
    if (!isValid) {
      return false;
    }

    // Search for any browser invalidated input fields and focus them
    let invalidFields = this.element.querySelectorAll("input:invalid");

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();

        isValid = false;
      }
    });

    return isValid;
  }
}
