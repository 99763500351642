import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--accent"
export default class extends Controller {
  connect() {
    // this.updateAccent();
  }

  updateAccent() {
    const accent = localStorage.getItem("zui-accent");
    if (accent) {
      document.documentElement.dataset.zuiAccent = accent;
    }
  }

  switchAccent(event) {
    const accent = event.params.accent;
    if (accent) {
      localStorage.setItem("zui-accent", accent);
      this.updateAccent();
    }
  }
}
