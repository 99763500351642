import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["field", "spinner", "icon"];

  submit() {
    this.iconTarget.classList.add("opacity-25");
    this.spinnerTarget.classList.remove("hidden");
    this.fieldTarget.form.requestSubmit();
    this.fieldTarget.disabled = true;
    this.fieldTarget.form.disabled = true;
  }
}