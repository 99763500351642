// Submits a form when a specific input changes.
// Allows setting a custom method and action to use whenever the form is submitted.
//
// Example:
//
// data: {
//   controller: "submit-on-change",
//   submit_on_change_method_value: "get",
//   submit_on_change_visit_action_value: "replace"
// }
//
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    trigger: { type: String, default: "change" },
    target: { type: String, default: "form" },
    visitAction: String,
    method: String,
    action: String
  };

  connect() {
    this.element.addEventListener(this.triggerValue, () => {
      let form = this.element.closest(this.targetValue)
      form.method = this.methodValue || form.method
      form.action = this.actionValue || form.action

      if (this.visitActionValue) {
        form.dataset.turboAction = this.visitActionValue
      }

      Turbo.navigator.submitForm(form)
    });
  }
}
