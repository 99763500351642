import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zui--toast"
export default class extends Controller {
  static targets = ["message"];

  connect() {
    this.updateZIndex();
  }

  updateZIndex() {
    const countOfMessages = this.messageTargets.length;
    this.element.style.zIndex = countOfMessages > 0 ? "100" : "-1";
  }

  messageTargetConnected() {
    this.updateZIndex();
  }

  messageTargetDisconnected() {
    this.updateZIndex();
  }
}
