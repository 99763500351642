import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "submit"];

  submit(event) {
    if (!((event.metaKey || event.ctrlKey || event.keyCode == 91 || event.keyCode == 93) && event.keyCode == 13))
      return;

    this.submitTarget.click()
  }
}
