import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["pagination", "loading"]

  paginationTargetConnected() {
    if (this.observer) return;

    const options = {
      threshold: 0
    }

    let _this = this;
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting && entry.intersectionRatio >= options.threshold) {
        _this.loadMore();
      }
    }, options);

    this.observer.observe(this.paginationTarget);
  }

  loadMore(element) {
    if (!this.paginationTarget) return false;

    let next_page = this.paginationTarget.querySelector("a[rel='next']");

    if (next_page) {
      next_page.click();
      next_page.remove();

      if (this.hasLoadingTarget)
        this.loadingTarget.classList.remove("hidden");
    }
  }
}
