import { Controller } from '@hotwired/stimulus';
import { enter, leave, toggle } from 'el-transition'

export default class extends Controller {
  static values = {
    autohide: { type: Boolean, default: true },
    timeout: { type: Number, default: 4000 },
    progressBar: { type: Boolean, default: true }
  }

  static targets = ['progress']

  connect() {
    this.progressUpdateInterval = 75;
    this.progressTimeout = null;
    this.mouseHovering = false;

    enter(this.element)

    if (this.autohideValue) {
      this.startAutohideTimer();
      this.setupMouseEvents();
    }
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', this.handleMouseEnter.bind(this));
    this.element.removeEventListener('mouseleave', this.handleMouseLeave.bind(this));
  }

  hide() {
    leave(this.element).then(() => {
      this.element.remove();
    })
  }

  setProgressWidth(timeRemainingUntilHide, totalTime) {
    let percent = (timeRemainingUntilHide / totalTime) * 100
    this.progressTarget.style.width = `${percent}%`

    let maxOpacity = 80

    if (percent < 30) {
      this.progressTarget.style.opacity = `${maxOpacity - (100 - percent) + 30}%`
    } else {
      this.progressTarget.style.opacity = `${maxOpacity}%`
    }
  }

  handleMouseEnter() {
    this.mouseHovering = true
  }

  handleMouseLeave() {
    this.mouseHovering = false
  }

  startAutohideTimer() {
    enter(this.progressTarget)
    this.timeRemainingUntilHide = this.timeoutValue

    this.progressTimeout = setInterval(() => {
      if (this.mouseHovering) {
        return
      }

      this.timeRemainingUntilHide -= this.progressUpdateInterval

      if (this.progressBarValue) {
        this.setProgressWidth(this.timeRemainingUntilHide, this.timeoutValue)
      }

      if (this.timeRemainingUntilHide <= 0) {
        clearInterval(this.progressTimeout)
        this.hide()
      }
    }, this.progressUpdateInterval)
  }

  setupMouseEvents() {
    this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
    this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
  }
}
