// Needs this to work with TurboFrames: https://github.com/hotwired/turbo/pull/59
//
// Modified from https://codepen.io/chriscoyier/pen/XWKEVLy
let turboVisitEvent = function (event) {
  document.querySelectorAll("textarea.autogrow").forEach((textarea) => {
    var wrapper = document.createElement('div');
    wrapper.classList.add('grow-wrap');
    textarea.parentNode.insertBefore(wrapper, textarea);
    wrapper.appendChild(textarea);
    textarea.classList.remove('autogrow');
    wrapper.dataset.replicatedValue = textarea.value;
    textarea.addEventListener("input", () => {
      wrapper.dataset.replicatedValue = textarea.value;
    });
  })
};

window.addEventListener('turbo:visit', turboVisitEvent, false);
window.addEventListener('turbo:frame-load', turboVisitEvent, false);