import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  selected(event) {
    let target = event.currentTarget;
    let paramName = target.getAttribute('name');
    let queryParams = new URLSearchParams(window.location.search);
    queryParams.set(paramName, target.value);
    window.location.search = queryParams.toString();
  }
}
