import { Controller } from "@hotwired/stimulus";
import { enter, leave, toggle } from "el-transition"

export default class extends Controller {
  static targets = [
    "input",
    "results",
    "backdrop",
    "modal",
    "selectedIndexField",
    "selectedTypeField",
    "selectedIdField",
  ];

  connect() {
    if (!this.inputTarget) throw new Error("inputTarget is required");
    if (!this.resultsTarget) throw new Error("resultsTarget is required");
    if (!this.backdropTarget) throw new Error("backdropTarget is required");
    if (!this.modalTarget) throw new Error("modalTarget is required");
    if (!this.selectedIndexFieldTarget)
      throw new Error("selectedIndexFieldTarget is required");
    if (!this.selectedTypeFieldTarget)
      throw new Error("selectedTypeFieldTarget is required");
    if (!this.selectedIdFieldTarget)
      throw new Error("selectedIdFieldTarget is required");

    window.addEventListener("keydown", this.onWindowKeyDown)
    this.inputTarget.addEventListener("keydown", this.onKeyDown)

    this.boundHideOnLinkClicked = this.hideOnLinkClicked.bind(this)
    this.modalTarget.addEventListener("click", this.boundHideOnLinkClicked)

    this.boundHideOnDismissElementClicked = this.boundHideOnDismissElementClicked.bind(this)
    this.backdropTarget.addEventListener("click", this.boundHideOnDismissElementClicked)
    this.modalTarget.addEventListener("click", this.boundHideOnDismissElementClicked)
  }

  disconnect() {
    window.removeEventListener("keydown", this.onWindowKeyDown)
    this.inputTarget.removeEventListener("keydown", this.onKeyDown)
    this.modalTarget.removeEventListener("click", this.boundHideOnLinkClicked)
    this.modalTarget.removeEventListener("click", this.boundHideOnDismissElementClicked)
    this.backdropTarget.removeEventListener("click", this.boundHideOnDismissElementClicked)
  }

  show() {
    enter(this.backdropTarget)
    enter(this.modalTarget);
    this.inputTarget.focus();
  }

  hide() {
    leave(this.backdropTarget)
    leave(this.modalTarget);
  }

  boundHideOnDismissElementClicked = e => {
    if (e.target == this.backdropTarget || e.target == this.modalTarget) {
      this.hide();
    }
  }

  hideOnLinkClicked = e => {
    if (e.target.tagName == "A" || e.target.closest("a")) {
      this.hide();
    }
  }

  onWindowKeyDown = e => {
    if (e.key == "k" && e.metaKey) {
      e.preventDefault();
      this.show();
    } else if (e.key == "/" && e.target.tagName != "INPUT") {
      e.preventDefault();
      this.show();
    } else if (e.key == "Escape") {
      this.hide();
    }
  }

  onKeyDown = e => {
    if (e.key == "ArrowUp") {
      this.moveSelected(-1);
    } else if (e.key == "ArrowDown") {
      this.moveSelected(1);
    } else if (e.key == "Enter") {
      e.preventDefault();
      this.visit(this.selectedResultElement());
    } else if (e.key == "Escape") {
      this.hide()
    } else {
      this.search();
    }
  }

  search() {
    clearTimeout(this.timeout);
    if (
      this.inputTarget.value.length > 2 ||
      this.inputTarget.value.length == 0
    ) {
      this.timeout = setTimeout(() => {
        this.inputTarget.form.requestSubmit();
      }, 100);
    }
  }

  moveSelected(distance) {
    if (!this.hasResults()) {
      this.deselectAll();
      return
    }

    let curIndex = this.selectedIndex()
    let nextIndex = null

    if (curIndex == null && distance > 0) {
      nextIndex = -1 + distance
    } else if (curIndex == null && distance < 0) {
      nextIndex = this.lastResultIndex() + 1 + distance
    } else {
      nextIndex = curIndex + distance
    }

    if (nextIndex > this.lastResultIndex()) {
      nextIndex = 0
    } else if (nextIndex < 0) {
      nextIndex = this.lastResultIndex()
    }

    this.selectIndex(nextIndex)
  }

  selectIndex(index) {
    this.deselectAll();
    let element = this.resultElementAtIndex(index);
    if (element) {
      element.classList.add("selected");
      this.scrollToElement(element);
      this.selectedTypeFieldTarget.value = element.dataset.resultType;
      this.selectedIdFieldTarget.value = element.dataset.resultId;
      this.selectedIndexFieldTarget.value = index;
    }
  }

  deselectAll() {
    this.resultElements().forEach((element) => {
      element.classList.remove("selected");
    })

    this.selectedTypeFieldTarget.value = ""
    this.selectedIdFieldTarget.value = ""
    this.selectedIndexFieldTarget.value = ""
  }

  selectedIndex() {
    let selectedElement = this.resultsTarget.querySelector(".selected");
    if (selectedElement) {
      return Array.prototype.indexOf.call(
        this.resultElements(),
        selectedElement
      );
    }
  }

  selectedResultElement() {
    return this.resultsTarget.querySelector(".selected");
  }

  resultElementAtIndex(index) {
    return this.resultElements()[index];
  }

  hasResults() {
    return (this.resultElements() || []).length > 0;
  }

  lastResultIndex() {
    if (!this.hasResults()) return null;
    return this.resultElements().length - 1;
  }

  resultElements() {
    return this.resultsTarget.querySelectorAll("li");
  }

  visit(el) {
    if (!el) return;
    el.querySelector("a").click();
  }

  scrollToElement(el) {
    if (!el) return;

    if (el.offsetTop <= 100) {
      this.resultsTarget.scrollTop = 0;
    } else if (el.offsetTop <= this.resultsTarget.scrollTop + 100) {
      this.resultsTarget.scrollTop = el.offsetTop - 100;
    } else {
      this.scrollToBeVisible(el, this.resultsTarget);
    }
  }

  scrollToBeVisible(el, container) {
    const elTop = el.offsetTop;
    const elBottom = elTop + el.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    if (elTop < containerTop) {
      // Scroll to the top of container
      container.scrollTop -= containerTop - elTop;
    } else if (elBottom > containerBottom) {
      // Scroll to the bottom of container
      container.scrollTop += elBottom - containerBottom;
    }
  }
}
