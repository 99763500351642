// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    content: String,
    url: String,
    interactive: { type: Boolean, default: false },
    maxWidth: { type: String, default: 'none' },
    theme: { type: String, default: '' },
    ajaxTheme: { type: String, default: 'light-border' },
    trigger: { type: String, default: 'mouseenter click' },
    touch: { type: String, default: 'hold' }
  };

  connect() {
    let options = {
      content: this.contentValue,
      theme: this.themeValue,
      trigger: this.triggerValue,
      touch: this.touchValue,
      interactive: this.interactiveValue,
      maxWidth: this.maxWidthValue
    };

    if (this.hasUrlValue) {
      options = {
        ...options,
        allowHTML: true,
        theme: this.ajaxThemeValue,
        onShow: (instance) => {
          if (this.cachedContent == null) {
            instance.setContent("&nbsp;"); // fix odd visual issue when tooltip is empty
            fetch(this.urlValue)
              .then((response) => response.text())
              .then((text) => {
                this.cachedContent = text;
                instance.setContent(this.cachedContent);
              })
              .catch((error) => (this.cachedContent = null));
          }
        },
      };
    }
    this.tippy = tippy(this.element, options);
  }

  disconnect() {
    this.tippy.destroy();
  }
}
