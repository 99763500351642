import { Controller } from "@hotwired/stimulus";

const THEME_LIGHT = "light";
const THEME_DARK = "dark";
const THEME_SYSTEM = "system";
const DARK_SCHEME_QUERY = "(prefers-color-scheme: dark)";

export default class extends Controller {
  connect() {
    // this.setInitialTheme();
    this.systemThemeMediaQuery = window.matchMedia(DARK_SCHEME_QUERY);
    this.systemThemeMediaQuery.addEventListener(
      "change",
      this.handleSystemThemeChange.bind(this)
    );
  }

  disconnect() {
    this.systemThemeMediaQuery.removeEventListener(
      "change",
      this.handleSystemThemeChange.bind(this)
    );
  }

  setInitialTheme() {
    let theme = localStorage.getItem("zui-theme");

    if (!theme) {
      if (document.documentElement.classList.contains(ZuiTheme.THEME_DARK)) {
        theme = ZuiTheme.THEME_DARK;
      } else {
        theme = ZuiTheme.THEME_SYSTEM;
      }
      localStorage.setItem("zui-theme", theme);
    }
    this.updateTheme();
  }

  switchTheme(event) {
    const { theme } = event.params;
    localStorage.setItem("zui-theme", theme);
    this.updateTheme();
  }

  updateTheme() {
    const theme = localStorage.getItem("zui-theme");

    if (theme === THEME_SYSTEM) {
      this.applySystemPreferenceTheme();
    } else {
      this.applyTheme(theme);
    }
  }

  applySystemPreferenceTheme() {
    const prefersDark = window.matchMedia(DARK_SCHEME_QUERY).matches;
    this.applyTheme(prefersDark ? THEME_DARK : THEME_LIGHT);
  }

  handleSystemThemeChange = () => {
    this.updateTheme();
  };

  applyTheme(theme) {
    if (theme === THEME_DARK) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }
}
