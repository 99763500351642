import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["content"];

  toggle() {
    this.contentTargets.forEach(function(t) {
      let c = t.dataset.togglerClass;
      t.classList.toggle(c);
    });
  }
}
