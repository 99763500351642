import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["body"];
  connect() {}

  toggle() {
    this.bodyTarget.classList.toggle("hidden");
  }

  hide() {
    this.bodyTarget.classList.add("hidden");
  }

  clickOutside(event) {
    if (this.element.contains(event.target) === false) {
      this.hide();
    }
  }
}
